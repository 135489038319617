import React from 'react';

function MainEvents() {

  return (
    <>
      <h1 className="has-text-centered mt-6">Hauptveranstaltung</h1>
      {/* <p className="has-text-centered mt-2">
        Wstęp <strong>wolny</strong> i każdy jest mile widziany.
      </p> */}
      <div className="event-info-container mt-4">
        {/* {mainEvents.map((mainEvent, index) => (
          <a
            href={"/veranstaltungen/" + mainEvent.frontmatter.slug}
            className="main-event-container has-text-centered mt-3 mb-3 mr-6 ml-6"
          >
            <div key={index}>
              <h2 className="m-1">{mainEvent.frontmatter.date2}</h2>
              <h3 className="mt-3">{mainEvent.frontmatter.title}</h3>
              <h5 className="m-1">{mainEvent.frontmatter.venue_name}</h5>
            </div>
          </a>
        ))} */}
      </div>
    </>
  );
}

export default MainEvents;
