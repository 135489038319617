import React from 'react';

const GetInvolvedConfirmation = () => (
  <div>
    <hr />
    <p>
    Thank you for your interest in being a part of the Will Graham Look Up Celebration at The Fountain.
     We are excited to partner with you in bringing the Good News to your community!
    </p>
   
  </div>
);

export default GetInvolvedConfirmation;
