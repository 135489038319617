import React from 'react';
import { Block } from '@bgea-js/react-ui';

const privacyPolicyCopyFountain = () => (
  <div>
    <Block textSize={6}>
      <p>
        The Billy Graham Evangelistic Association (BGEA) requires volunteers
        (ages 18 and over) to complete a background check as part of the
        application process. An e-mail will be sent to you with instructions. By
        providing your contact information, you will also be signing up to learn
        more about the global ministries of BGEA.{' '}
        <a
          style={{ color: '#3E7BBA', textDecoration: 'underline' }}
          href="https://billygraham.org/privacy-policy/"
        >
          BGEA’s Privacy Policy
        </a>
        .
      </p>
    </Block>
  </div>
);

export default privacyPolicyCopyFountain;
