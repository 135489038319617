import React from 'react';

const GetInvolvedConfirmation = () => (
  <div>
    <hr />
    <p>
      Thank you for your interest in being a part of the Northern Colorado Look
      Up Celebration. We are excited to partner with you in bringing the Good
      News to your community!
    </p>
    <p>
      This Celebration would not be possible without an army of volunteers to
      help spread the word and invite others to hear the life-changing message
      of the Gospel.
    </p>
    <p>
      You will receive a volunteer kit* in the mail which you can use to invite
      your family and friends to attend. It includes posters, invitations, and a
      bookmark to remind you to pray for those you are inviting.
      <strong>
        <em> *Volunteer Kits will not be mailed after September 27, 2024.</em>
      </strong>
    </p>
    <p>
      In addition to spreading the word, we need volunteers to serve in the
      following roles on both days of the Celebration, October 5th and 6th.
    </p>
    <h2>Counselor</h2>
    <p>
      Counselors serve at the event by praying with those who stand to make a
      decision for Christ during the Gospel invitation. All counselors are
      required to attend the Christian Life and Witness Course (CLWC) and
      complete a counselor application. You must be 13 years of age or older to
      apply, and those 18 years of age or over must pass a free criminal
      background check prior to serving.
    </p>
    <h2>On-Site Team</h2>
    <p>
      On-Site Team members serve before, during, or after the event to help with
      set-up, greeting, ushering, staff resource tents, or tear-down after the
      event ends. As a part of the On-Site Team, you will have the opportunity
      to sign-up for the shift time that works best for you. You must be 13
      years of age or older to serve, and those 18 years of age or over must
      pass a free criminal background check prior to serving.
    </p>
    <p>
      Physical requirements to serve as part of the On-Site Team include:
      <ul>
        <li>Moderate lifting.</li>
        <li>
          The ability to bend, stoop, and stand for long periods of time, climb
          stairs, and walk moderate distances.
        </li>
      </ul>
    </p>
    <h2>Kidzfest Team</h2>
    <p>
      Kidzfest Team members serve during the Saturday morning event for children
      by helping in various roles—from greeting attendees to helping facilitate
      games and activities following the Gospel invitation. We will also need
      counselors to serve during Kidzfest to pray with those who make a decision
      for Jesus. All Kidzfest volunteers must be 13 years of age or older and
      those 18 years of age or over must pass a free criminal background check
      prior to serving.
    </p>
    <p>
      <strong>
        <em>
          Please note: If you are under the age of 18, parent/legal guardian
          consent must be received by the Billy Graham Evangelistic Association
          (BGEA) before your volunteer kit can be mailed and before you can
          serve on-site the day of the event.
        </em>
      </strong>
    </p>
    <h2>Select Your Volunteer Role:</h2>
    <p>
      To select your volunteer role at the Northern Colorado Look Up
      Celebration, please fill out the{' '}
      <a
        href="https://events.billygraham.org/AOWMA1"
        rel="noreferrer"
        target="_blank"
      >
        <strong style={{ 'color': '#3E7BBA', 'text-decoration': 'underline' }}>
          *CVENT form here.
        </strong>
      </a>{' '}
      When filling out the CVENT form, please use the same name and email as you
      did to fill out the Get Involved form.
    </p>
  </div>
);

export default GetInvolvedConfirmation;
